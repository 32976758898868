<template lang='pug'>
  .getstarted
    particles
      h1.animated.fadeInUp 5651 Sayılı Kanun
      //h3.animated.fadeInUp.wait-p2s Siz Seçin | Biz kuralım
    v-container(grid-list-xl)
      v-layout(row, wrap)
        v-flex(xs12, sm6)
          //.getstarted-ver.animated.fadeInUp
            //- img.getstarted-ver-icon(:src='require(`../assets/icons/local.png`)', alt='Stable')
            //- v-chip.my-3(color='black', dark, outline)
            //-   .body-2 lOCAL SERVER
            //- //h2(v-html='stable')
            //- //.body-2 Recommended for production use
            //- .getstarted-ver-req
            //-   strong Neden Local Server?
            //-   ul
            //-     li Donanım yatırımı yapılması gerekmektedir.
            //-     li Elektrik, İnternet, Soğutma sistemi olması gerekmektedir.
            //-     li Tek seferlik ücret vardır.
            //- v-btn(color='teal', large, dark, href='https://docs-legacy.requarks.io/wiki/install')
            //-   span Setup Instructions
        v-flex(xs12)
          .getstarted-ver.animated.fadeInUp.wait-p1s
            img(height=400 src='https://res.cloudinary.com/sekuritim/image/upload/v1575918845/WifiFastSpot/zamane2.jpg')
            v-btn.my-3(color='black',large, dark, outline, href="https://www.mevzuat.gov.tr/MevzuatMetin/1.5.5651.pdf", target = " ")
              .body-2 5651 Sayılı Kanun
            //h2(v-html='beta')
            //.body-2 For development and testing
            .getstarted-note.animated.fadeInUp.wait-p2s
             // strong Neden Cloud Server?
              ul
                li Altyapı sermayesini azaltarak maliyet tasarrufu
                li Tamamen yedekli yapı
                li Yıllık ödeme vardır.

            //- v-flex(xs12)
            //-   .getstarted-note.animated.fadeInUp.wait-p2s
            //-   v-btn.mx-0.hidden-sm-and-down.animated.fadeInRight(color='primary', outline, large, href='https://www.mevzuat.gov.tr/MevzuatMetin/1.5.5651.pdf') Mevzuat Hakkında

            //- v-btn(color='blue', large, dark, href='https://docs.requarks.io/install')
            //-   span Setup Instructions
        // v-flex(xs12)
          .getstarted-note.animated.fadeInUp.wait-p2s
            v-btn(color='dark', large, dark, href='https://www.mevzuat.gov.tr/MevzuatMetin/1.5.5651.pdf' target = " ") Mevzuat Hakkında
        //- v-flex(xs12)
        //-   .getstarted-note.animated.fadeInUp.wait-p3s

        //-     h3 Neden log serverına ihtiyacım var?
        //-     h5 5651 sayılı kanun, İnternet Ortamında Yapılan Yayınların Düzenlenmesi Ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkındaki Kanundur.
        //-     h4 Bu kanun uyarınca Oteller, Restaurantlar, Alışveriş Merkezleri, Kafeler, Internet Kafeler, Üniversiteler, KOBİ'ler, fabrikalar gibi interneti kablolu ya da kablosuz kullanıma sunan tüm işletmeler yani internet toplu kullanım sağlayıcılar,
        //-     ul
        //-       li Kendi iç ağlarında dağıtılan IP adres bilgilerini, kullanıma başlama ve bitiş tarih ve saatini ve bu IP adreslerini kullanan bilgisayarların MAC adresini gösteren bilgileri elektronik ortamda sistemlerine kaydetmekle yükümlüdür .
        // -li Bu bilgilerin doğruluğunu, bütünlüğünü ve oluşan verilerin dosya bütünlük değerlerini zaman damgası (hash) ile birlikte günlük olarak bir yıl süre ile saklamak ve gizliliğini temin etmekle yükümlüdür.
</template>

<script>
import Particles from '../components/Particles'
import { get } from 'vuex-pathify'

export default {
  components: {
    Particles
  },
  data () {
    return { }
  },
  computed: {
    stable: get('stable'),
    beta: get('beta')
  }
}
</script>

<style lang='scss'>
.getstarted {
  > .container {
    max-width: 1200px;
  }

  &-ver {
    background: var(--v-greyish-base) linear-gradient(45deg, transparent, var(--v-greyish-lighten4));
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 12px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    transition: all .6s ease;
    position: relative;

    &-icon {
      height: 64px;
    }

    &-req {
      padding: 12px;
      background-color: var(--v-greyish-lighten2);
      border-radius: 8px;
      width: 100%;
      margin: 24px 0;
      height: 120px;

      ul {
        margin-top: 12px;
        padding: 0;
        list-style-position: inside;

        li {
          em {
            font-style: normal;
            font-weight: 500;
            color: var(--v-greyish-darken1);
          }
        }
      }
    }

    .v-btn {
      img {
        height: 24px;
        margin-right: 12px;
      }
    }
  }

  &-note {
    background: var(--v-greyish-lighten2) linear-gradient(45deg, transparent, rgba(255,255,255,.5));;
    border-radius: 8px;
    padding: 12px;
    text-align: center;
    color: var(--v-greyish-darken1);

    h3 {
      font-weight: 500;
      color: var(--v-greyish-darken2);
    }

    ul {
      padding: 0;
      margin: 12px 0 0 0;
      list-style-position: inside;
    }
  }
}
</style>
